<template>
  <el-dialog
        title="修改密码"
        :visible.sync="dialogVisible"
        :before-close="handleClose"
       >
       <el-form :model="form" :rules="rules" ref="form" >
            <el-form-item label="旧密码" prop="oldPassword" :label-width="formLabelWidth">
                <el-input v-model="form.oldPassword" show-password placeholder="请输入新密码"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPassword" :label-width="formLabelWidth">
                <el-input v-model="form.newPassword" show-password placeholder="请输入新密码"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="surePassword" :label-width="formLabelWidth">
                <el-input v-model="form.surePassword"  show-password placeholder="请再次输入新密码"></el-input>
            </el-form-item>
       </el-form>
       <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save()">确认保存</el-button>
            <el-button @click="handleClose">取 消</el-button>
        </div>
  </el-dialog>
</template>

<script>
export default {
    props: ['editPasswordData'],
    data(){
        return {
            dialogVisible: this.editPasswordData.dialogFlag,
            authorityId45:this.urlApi.API.authorityId45,//修改密码
            AdminToken:localStorage.getItem('token'),//token
            form:{
                oldPassword:"",//旧密码
                newPassword:"",//新密码
                surePassword:"",//确认密码
            },
            rules:{
                oldPassword:[{required:true,message: '请输入旧密码', trigger: 'blur'}],
                newPassword:[{required:true,message: '请输入新密码', trigger: 'blur'}],
                surePassword:[
                    {required:true,message: '请确认密码', trigger: 'blur'},
                    {validator:this.validateFieldsSame,trigger: 'blur'}
                ]
            },
            formLabelWidth:'120px'
        }
    },
    methods:{
        //校验2次密码输入是否一致
        validateFieldsSame(rule, value, callback) {
            if (value !== this.form.newPassword) {
                callback(new Error('两次输入密码不一致'));
            } else {
                callback();
            }
        },
        //关闭弹窗
        handleClose(){
            this.editPasswordData.dialogFlag = false;
        },
        //修改密码
        save(){
            var form = this.form;
            this.$refs.form.validate((valid)=>{
                if(valid){
                   //调接口 
                   let param = {
                        AdminToken:this.AdminToken,
                        password_old:this.form.oldPassword,
                        password_new:this.form.newPassword
                   }
                   this.$post(this.authorityId45,param,this).then((res)=>{
                        if(res.code == 200){
                            this.$message({
                                type: 'success',
                                message: '密码修改成功，请重新登录!'
                            });
                            this.$router.push({path:'/login'})
                        }else{
                            this.$message.error(res.msg);
                        }
                    }).catch(res=>{
                        this.$message.error(res.msg);
                    })
                }else{
                    return false
                }
            })
            // this.editPasswordData.dialogFlag= false
        }
    }

}
</script>

<style>

</style>