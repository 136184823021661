export default{
  //输入时间转换成年-月-日 时：分：秒
  cstToYmdHms(cstString) {
    // 将CST时间字符串转换为Date对象
    const cstDate = new Date(cstString);
  
    // 获取年月日时分秒
    const year = cstDate.getFullYear();
    const month = ('0' + (cstDate.getMonth() + 1)).slice(-2); // 月份是从0开始的
    const day = ('0' + cstDate.getDate()).slice(-2);
    const hours = ('0' + cstDate.getHours()).slice(-2);
    const minutes = ('0' + cstDate.getMinutes()).slice(-2);
    const seconds = ('0' + cstDate.getSeconds()).slice(-2);
  
    // 返回格式化的日期时间字符串
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  },
  //将如期转换成年-月-日
  cstToYmd(cstString) {
    if(cstString == null){
      return 
    }
    // 将CST时间字符串转换为Date对象
    const cstDate = new Date(cstString);
  
    // 获取年月日时分秒
    const year = cstDate.getFullYear();
    const month = ('0' + (cstDate.getMonth() + 1)).slice(-2); // 月份是从0开始的
    const day = ('0' + cstDate.getDate()).slice(-2);
  
    // 返回格式化的日期时间字符串
    return `${year}-${month}-${day}`;
  },
  //判断是否有权限 有返回true 没有返回false
  hasAuthorityId(val){
    const arr = localStorage.getItem('authorityIds')
    return arr.includes(val)
  },
  //下载
  download(url){
    const csvUrl = url;
    // 创建一个a标签用于下载文件
    const link = document.createElement('a');
    link.style.display = 'none'; // 确保a标签不显示在页面上
    link.href = csvUrl;
    // link.download = '信息管理列表.csv'; // 设置下载文件的名称
    // 将a标签添加到文档中，并模拟点击
    document.body.appendChild(link);
    link.click();
    // 清理
    document.body.removeChild(link);
},
  //循环递归获取children下的id
  // findAllChildren(items, result = []) {
  //   for (let item of items) {
  //     if (item.children && item.children.length > 0) {
  //         result.push(item.dept_id);
  //         findAllChildren(item.children, result);
  //     }
  //   }
  //   return result;
  // },
}
