import self_name from "@/publicUrl";
let domain = self_name.url
let API = {
    //登录
    authorityId1:domain + "/admin/system.Login/login",
    //退出登录
    authorityId2:domain + "/admin/system.Login/logout",
    //提单列表 报名信息列表（录入报名信息，根据姓名筛选相似数据）
    authorityId3:domain + "/admin/signup.Index/list",
    //提单信息列表(根据id查)
    authorityId4:domain + "/admin/signup.Index/info",
    //提单添加
    authorityId5:domain + "/admin/signup.Index/add",
    //删除提单
    authorityId6:domain + "/admin/signup.Index/dele",
    //修改提单
    authorityId7:domain + "/admin/signup.Index/edit",
    //站内信接收列表
    authorityId8:domain + "/admin/system.Mail/list",
    //发送站内信
    authorityId9:domain + "/admin/system.Mail/send",
    //站内信发送记录
    authorityId10:domain + "/admin/system.Mail/record",
    //更新站内信状态（标记为已读）
    authorityId11:domain + "/admin/system.Mail/updateMailStatus",
    //站内信未读数量
    authorityId12:domain + "/admin/system.Mail/mailUnreadCount",
    //部门用户
    authorityId13:domain + "/admin/system.Dept/user",
    //部门列表
    authorityId14:domain + "/admin/system.Dept/list",
    //员工删除
    authorityId15:domain + "/admin/system.User/dele",
    //添加员工
    authorityId16:domain + "/admin/system.User/newAdd",
    //修改员工
    authorityId17:domain + "/admin/system.User/newEdit",
    //根据id查用户（员工）信息
    authorityId18:domain + "/admin/system.User/newInfo",
    //部门添加
    authorityId19:domain + "/admin/system.Dept/add",
    //部门修改
    authorityId20:domain + "/admin/system.Dept/edit",
    //根据id获取部门信息
    authorityId21:domain + "/admin/system.Dept/info",
    //部门删除
    authorityId22:domain + "/admin/system.Dept/dele",
    //删除站内信历史记录
    authorityId23:domain + "/admin/system.Mail/recordDel",
    //获取负责人列表
    authorityId25:domain + "/admin/system.User/newList",
    //获取角色列表
    authorityId26:domain + "/admin/system.Role/list",
    //获取角色用户（根据角色id获取用户）
    authorityId27:domain + "/admin/system.Role/user",
    //添加角色
    authorityId28:domain + "/admin/system.Role/add",
    //角色用户解除
    authorityId29:domain + "/admin/system.Role/userRemove",
    //角色修改
    authorityId30:domain + "/admin/system.Role/edit",
    //获取角色信息
    authorityId31:domain + "/admin/system.Role/info",
    //删除角色
    authorityId32:domain + "/admin/system.Role/dele",
    //用户修改角色
    authorityId33:domain + "/admin/system.User/editrole",
    //菜单列表（权限操作列表）
    authorityId34:domain + "/admin/system.Menu/list",
    //保存角色对应的权限
    authorityId35:domain + "/admin/system.Role/editmenu",
    //提交表
    authorityId36:domain + "/admin/signup.Index/editState/2",
    //提交资料
    authorityId37:domain + "/admin/signup.Index/editState/3",
    //撤单
    authorityId38:domain + "/admin/signup.Index/editState/4",
    //上交
    authorityId39:domain + "/admin/signup.Index/editState/5",
    //下证时间
    authorityId40:domain + "/admin/signup.Index/editState/6",
    //快递单号
    authorityId41:domain + "/admin/signup.Index/editState/7",
    //获取我的信息
    authorityId42:domain + "/admin/system.UserCenter/info",
    //图片上传
    authorityId43:domain + "/admin/signup.Index/imageUpload",
    //提单图片列表
    authorityId44:domain + "/admin/signup.Index/imageList",
    //修改密码
    authorityId45:domain + "/admin/system.UserCenter/pwd",
    //导出
    authorityId46:domain + "/admin/signup.Index/listExport",
    //联想字段记录列表
    authorityId47:domain + "/admin/signup.Record/recordList",
    //下载待传快递单号导入模板
    authorityId48:domain + "/admin/signup.Index/expressListExport",
    //批量导入报名信息
    authorityId49:domain + "/admin/signup.Index/listImport",
    //批量导入快递单号
    authorityId50:domain + "/admin/signup.Index/expressListImport",

}
export default{
    API:API
}