import Vue from 'vue'
import Vuex from 'vuex'


import { getItem, setItem } from "@/utils/storage";

Vue.use(Vuex)


const TOKEN_KEY = "X-Token";

export default new Vuex.Store({
  state: {
    token:null || JSON.parse(window.localStorage.getItem(TOKEN_KEY)),
    LOADING: false,
    user: getItem(TOKEN_KEY),
  },
  getters: {
    // getToken(state,token){
    //   return state.token || localStorage.getItem("token") || "";
    // }
  },
  mutations: {
    // setToken(state,token){
    //   state.token = token;
    //   localStorage.setItem('token',token)
    // },
    // delToken(state){
    //   state.token = ""
    //   localStorage.removeItem("token");
    // },
    // setUserInfo(state,userName){
    //   state.userName = userName
    // },
    setUser(state, data) {
      state.user = data;
      // 把数据备份到本地存储，防止vuex刷新丢失
      // window.localStorage.setItem(TOKEN_KEY, JSON.stringify(state.user))
      setItem(TOKEN_KEY, state.user);
    },
    showLoading(state) {
      state.LOADING = true;
    },
    hideLoading(state) {
      state.LOADING = false;
    },
  },
  actions: {
  },
  modules: {
  }
})
