import Vue from 'vue'
import axios from 'axios'
import { getToken ,removeToken,get} from '@/utils/auth'
import self_name from "@/publicUrl";
import router from '@/router'

// import LoginPage from '@/views/LoginView';
// Vue.use(Router);


let that = this
const domain = self_name.url
export function postAjax(url, data = {},that='') {
  // open()
  if(url.indexOf('http://') == -1 && url.indexOf('https://') == -1) {
    url = domain + url
  }
  !that ? showFullScreenLoading() : (that.loading = true);
  return new Promise((resolve, reject) => {
    axios.post(url, data, {
        headers: {
          // Authorization: "Bearer " + getToken(''),
          // platform: "3",
          // version: "3.0",
          // sign: "",
          // orgId: store.getters.GET_USERINFO.orgId || "",
          // token: getToken() || "",
          // venueId: JSON.parse(sessionStorage.user_Info).gymIds || ""
          'AdminToken':getToken() || "",
        }
      }).then(
        response => {
          // if(that){
          //   that.loading=false
          // }else{
          //   setTimeout(() => {
          //     tryHideFullScreenLoading();
          //   }, 1000);
          // }
          // close();
          resolve(response.data);
        },
        err => {
          setTimeout(function() {
            if (that) {
              that.loading = false;
            }else{
              tryHideFullScreenLoading();
            }
        }, 1000);
          // close();
          reject(err);
        }
      );
  })
}

// get封装
export function getAjax(url, params = {}) {
  // open()
  return new Promise((resolve, reject) => {
    axios.get(url, params, {
      headers: {
        // 'Authorization': 'Bearer ' + getToken(),
        // 'platform': '3',
        // 'version': '3.0',
        // 'sign': '',
        // 'orgId':store.getters.GET_USERINFO.orgId ||'',
        // 'token': getToken() || '',
        // 'venueId':store.getters.GET_USERINFO.gymIds||''
        'AdminToken':getToken() || "",
      }
    }).then(response => {
        // close()
        // setTimeout(function() {
        //   tryHideFullScreenLoading();
        // }, 1000);
        resolve(response.data)
      }, err => {
        // close()
        // setTimeout(function() {
        //   tryHideFullScreenLoading();
        // }, 1000);
        reject(err)
      })
  })
}

//上传文件
export function uploadAjax (url, data = {}) {
  // open()
  return new Promise((resolve,reject)=>{
    axios.post(url,data, {
        headers: {
          // 'Authorization': 'Bearer ' + getToken(),
          // 'platform': '3',
          // 'version': '3.0',
          // 'sign': '',
          // 'orgId':store.getters.GET_USERINFO.orgId ||'',
          // 'token': getToken() || '',
          // 'venueId':store.getters.GET_USERINFO.gymIds||''
          'AdminToken':getToken() || "",
        }
    })
    .then(response => {
      // close()
      setTimeout(function() {
        tryHideFullScreenLoading();
    }, 1000);
      resolve(response.data)
    }, err => {
      // close()
      setTimeout(function() {
        tryHideFullScreenLoading();
    }, 1000);
      reject(err)
    })
  })
}

// 下载文件
export function downAjax (url, data = {},that='') {
  // open()
  if(url.indexOf('http://') == -1 && url.indexOf('https://') == -1) {
    url = domain + url
  }
  !that ? showFullScreenLoading() : (that.loading = true);
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: {
          // Authorization: "Bearer " + getToken(),
          // platform: "3",
          // version: "3.0",
          // sign: "",
          // orgId: store.getters.GET_USERINFO.orgId || "",
          // token: getToken() || "",
          // responseType:'blob',
          // venueId: JSON.parse(sessionStorage.user_Info).gymIds || ""
          'AdminToken':getToken() || "",
        }
      })
      .then(

        response => {
          if(that){
            that.loading=false
          }else{
            setTimeout(() => {
              tryHideFullScreenLoading();
            }, 1000);
          }
          // close();
          resolve(response.data);
        },
        err => {

          setTimeout(function() {
            if (that) {
              that.loading = false;
            }else{
              tryHideFullScreenLoading();
            }

        }, 1000);
          // close();
          reject(err);
        }
      );
  })
}
//响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  if(response.data.code == 401){
    localStorage.clear();
    router.push({path:'/login'})
  }
  return response;
}, function (error) {
  // 对响应错误做点什么
  // if (error.response) {
    // switch (error.response.status) {
    //     // 返回401，清除token信息并跳转到登录页面
    //     case 401:
    //       localStorage.removeItem('token');
    //       // router.replace({
    //       //     path: '/login'
    //       //     //登录成功后跳入浏览的当前页面
    //       //     // query: {redirect: router.currentRoute.fullPath}
    //       // })
    // }
    // 返回接口返回的错误信息
    return Promise.reject(error.response.data);
// }
});