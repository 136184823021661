import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Layout from '../views/layout/index'

Vue.use(VueRouter)

function hasAuthorityId(val){
  const arr = localStorage.getItem('authorityIds')
  if(arr == null){
    return
  }else{
    return arr.includes(val)
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Layout,
    meta: {
      title: '首页',
      hidden: true
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      title: '关于',
      hidden: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    meta: {
      title: '登录',
      hidden: true
    }
  },
  {
    path:'/registrationInformation',
    name:'registrationInformation',
    component:Layout,
    meta:{
      title:"报名信息管理",
      // hidden:!hasAuthorityId('authorityId3'),
      hidden:false,
    },
    children: [
      {
        path: "/registrationInformation",
        component: () => import("@/views/registrationInformation/registrationInformation.vue"),
      },
    ]
  },
  {
    path:'/enterInformation',
    name:'enterInformation',
    component:Layout,
    meta:{
      title:"录入报名信息",
      hidden:!hasAuthorityId('authorityId5'),
      // hidden:false,
    },
    children: [
      {
        path: "/enterInformation",
        component: () => import("@/views/enterInformation/enterInformation.vue"),
      },
    ]
  },
  {
    path:'/rolePermission',
    component: Layout,
    name:'rolePermission',
    meta:{
      title:"角色权限管理",
      hidden:!hasAuthorityId('authorityId26'),
      // hidden:false,
    },
    children: [
      {
        path: "/rolePermission",
        component: () => import("@/views/rolePermission/index.vue"),
      },
    ]
  },
  {
    path:'/employeeDepartment',
    component: Layout,
    name:'employeeDepartment',
    meta:{
      title:"员工列表",
      // hidden:false,
      hidden:!(hasAuthorityId('authorityId14') && hasAuthorityId('authorityId25'))
    },
    children: [
      {
        path: "/employeeDepartment",
        component: () => import("@/views/employeeDepartment/department.vue"),
      },
    ]
  },

]

const router = new VueRouter({
  routes
})

router.beforeEach((to,from,next)=>{
  // 读取数据
  let token = localStorage.getItem('token');
  if(token){//如果有token，就允许去访问 对应的组件 
    next();//放行---页面执行放行操作
  }else{//如果没有token，就跳转到登录页
    if(to.path === '/login'){
      next();
    }else{
      next('/login');
    }
  }
})
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch(err => err);
};
export default router
