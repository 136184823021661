import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui'
import { postAjax, getAjax, uploadAjax, downAjax } from '@/utils/https.js'
import common from'@/components/common.js'
import {region} from '@/components/area.js'



import './assets/style/element-variables.scss'
Vue.use(ElementUI)
Vue.prototype.$http = axios
Vue.prototype.$post = postAjax
Vue.prototype.$down = downAjax
Vue.prototype.$get = getAjax
Vue.prototype.$upload = uploadAjax
Vue.prototype.common = common
Vue.prototype.region = region

Vue.config.productionTip = false

import AFTableColumn from 'af-table-column'
Vue.use(AFTableColumn)

import api from '@/utils/request'
Vue.prototype.urlApi = api

import downloadSrc from './utils/downloadSrc'
Vue.prototype.downloadSrcAll = downloadSrc
import '@/assets/css/public.css'


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
