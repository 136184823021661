<template>
  <el-dialog
        title="收信箱"
        :visible.sync="dialogVisible"
        :before-close="handleClose"
       >
        <div v-if="flag">
            <div style="max-height: 400px;overflow-y:scroll;margin-bottom:20px">
                <div v-for="(item,index) in letterData" :key="index" :class="item.status == 1 ? 'weidu-letter-box':'letter-box'" @click="changeReadStatus(0,item.mail_id,item.signup_id)">
                    <img src="@/assets/images/notice.svg" alt="" style="width:20px;height:20px;">
                    <span style="margin-left: 10px;">{{ item.content }}</span>
                    <span style="float:right;color:#409eff;cursor:pointer;font-size:12px;margin-top:3px;" @click="lookEnterInfo(item.signup_id)" v-if="common.hasAuthorityId('authorityId4')">查看报名信息</span>
                </div>
            </div>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20]"
                :page-size="limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="count">
            </el-pagination>
        </div>
        <div v-if="!flag">
            <el-form ref="form" :rules="rules" :model="form" label-width="80px" :inline="true" >
                <el-form-item label="学员姓名"  prop="signup_name">
                    <el-input :disabled="disabled" v-model="form.signup_name" placeholder="请输入学员姓名"></el-input>
                </el-form-item>
                <el-form-item label="机构名称" prop="signup_coopcompany">
                    <el-autocomplete value-key="name" :disabled="disabled" class="inline-input" v-model="form.signup_coopcompany" :fetch-suggestions="queryjigouSearch" placeholder="请输入机构名称" @select="handleSelect"></el-autocomplete>
                    <!-- <el-input :disabled="disabled" v-model="form.signup_coopcompany" placeholder="请输入机构名称"></el-input> -->
                </el-form-item>
                <el-form-item label="缴费日期" prop="signup_paytime" >
                    <el-date-picker :disabled="disabled" type="datetime" placeholder="选择日期" v-model="form.signup_paytime" style="width: 100%;"></el-date-picker>
                </el-form-item>
                <el-form-item label="身份证号" prop="signup_idcard">
                    <el-input :disabled="disabled" v-model="form.signup_idcard" placeholder="请输入身份证号"></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="signup_tel">
                    <el-input :disabled="disabled" v-model="form.signup_tel" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item label="报考工种" prop="signup_class">
                    <el-autocomplete :disabled="disabled" class="inline-input" v-model="form.signup_class" :fetch-suggestions="querySearch" placeholder="请输入内容" @select="handleSelect"></el-autocomplete>
                </el-form-item>
                <el-form-item label="报名形式" prop="signup_openclasstype">
                    <el-select :disabled="disabled" v-model="form.signup_openclasstype" filterable placeholder="请选报名形式"  @change="changeSignUp()">
                        <el-option v-for="(item,index) in openclasstypeList" :label="item.value" :value="item.id" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="考试形式" prop="signup_classtype">
                    <!-- <el-checkbox-group v-model="form.signup_classtypearr"  >
                        <el-checkbox :disabled="disabled" v-for="(item,index ) in examList" :label="item.label" :key="index" @change="handleCheckedChange(item)">{{ item.value }}</el-checkbox>
                    </el-checkbox-group> -->
                    <el-select :disabled="disabled" v-model="form.signup_classtype" filterable placeholder="请选考试形式">
                        <el-option v-for="(item,index) in examList" :label="item.value" :value="item.id" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="颁证单位" prop="signup_issuer">
                    <el-autocomplete value-key="name" :disabled="disabled" class="inline-input" v-model="form.signup_issuer" :fetch-suggestions="queryissuerSearch" placeholder="请输入颁证单位名称" @select="handleSelect"></el-autocomplete>
                    <!-- <el-input :disabled="disabled" v-model="form.signup_issuer" placeholder="请输入颁证单位"></el-input> -->
                </el-form-item>
                <el-form-item label="报考地区" prop="areaId">
                    <el-cascader :disabled="disabled" :options="areaList" v-model="form.areaId" :props="{  label: 'name', value: 'name',checkStrictly: true }" clearable filterable></el-cascader>
                </el-form-item>
                <el-form-item label="报考级别" prop="signup_level">
                    <el-input :disabled="disabled" v-model="form.signup_level" placeholder="请输入级别"></el-input>
                </el-form-item>
                <el-form-item label="报名价格" prop="signup_price">
                    <el-input :disabled="disabled" v-model="form.signup_price" placeholder="请输入价格"></el-input>
                </el-form-item>
                <el-form-item label="开课情况" prop="signup_openclass">
                    <el-select v-model="form.signup_openclass" filterable placeholder="请选择开课情况" :disabled="classStatusFlag || disabled2">
                        <el-option v-for="(item,index) in openclassList" :label="item.value" :value="item.id" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="负责人" prop="resp_uid" v-if="fuzerenflag">
                    <el-select :disabled="disabled" v-model="form.resp_uid" filterable placeholder="请选择负责人" >
                        <el-option  v-for="(item,index) in directorList" :label="item.name+'-'+item.username" :value="item.user_id" :key="index"></el-option>
                    </el-select>
                    <!-- </el-select><el-autocomplete class="inline-input" v-model="form.resp_uid" :fetch-suggestions="querydirectorList" placeholder="请输入负责人姓名" @select="handleSelect"></el-autocomplete> -->
                </el-form-item>
                <el-form-item label="登录密码" prop="signup_classpwd">
                    <el-input :disabled="disabled2" v-model="form.signup_classpwd" placeholder="请输入密码"></el-input>
                </el-form-item>
                <el-form-item label="准考证" prop="signup_zhkzdate">
                    <el-date-picker :disabled="disabled2" type="datetime" placeholder="选择准考证下发日期" v-model="form.signup_zhkzdate" style="width: 100%;"></el-date-picker>
                </el-form-item>
                <el-form-item label="考试时间" prop="signup_examtime">
                    <el-date-picker :disabled="disabled2" v-model="form.signup_examtime" type="datetime" placeholder="选择考试日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="考试成绩" prop="signup_score">
                    <el-input :disabled="disabled2" v-model="form.signup_score" placeholder="请输入考试成绩"></el-input>
                </el-form-item>
                <el-form-item label="考试结果" prop="signup_achievement">
                    <el-select :disabled="disabled2" v-model="form.signup_achievement" filterable placeholder="请选择考试结果">
                    <el-option v-for="(item,index) in examresultList" :label="item.value" :value="item.id" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="下证日期" prop="signup_certdate">
                    <el-date-picker :disabled="disabled2" type="datetime" placeholder="选择日期" v-model="form.signup_certdate" style="width: 100%;"></el-date-picker>
                </el-form-item>
                <el-form-item label="我的备注" prop="remark">
                    <el-input
                    type="textarea"
                    autosize
                    placeholder="请输入备注"
                    v-model="form.remark">
                    </el-input>
                </el-form-item>
                <!-- <el-form-item>
                    <el-button type="primary" @click="onSubmit" v-if="flag">立即创建</el-button>
                    <el-button type="primary" @click="onSubmit" v-if="!flag">修改</el-button>
                    <el-button @click="clearForm">清空</el-button>
                </el-form-item> -->
            </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="changeReadStatus(all)" v-if="flag">全部已读</el-button>
            <el-button @click="handleClose" v-if="flag">取 消</el-button>
            <el-button type="primary" @click="onSubmit" v-if="!flag">修 改</el-button>
            <el-button @click="back" v-if="!flag">返 回</el-button>
        </div>
  </el-dialog>
</template>

<script>
import { EventBus } from '@/event-bus.js';
export default {
    props:['lookLetterData'],
    data(){
        return{
            authorityId4:this.urlApi.API.authorityId4,//根据id查找提单信息
            authorityId7:this.urlApi.API.authorityId7,//修改用户提单信息
            authorityId8:this.urlApi.API.authorityId8,//站内信列表
            authorityId11:this.urlApi.API.authorityId11,//更新站内信状态(标记为已读)
            authorityId25:this.urlApi.API.authorityId25,//获取负责人列表
            authorityId47:this.urlApi.API.authorityId47,//获取联想字段列表
            AdminToken:localStorage.getItem('token'),//token
            roleType:localStorage.getItem('role_type'),//获取当前登录人身份 0 运营 1销售
            dialogVisible: this.lookLetterData.dialogFlag,
            all:this.lookLetterData.all,//是否全局标为已读 1为全局标为已读 0为当条数据操作下的站内信为已读
            allids:[],//每个学员下的未读站内信mail_id
            flag:true,
            currentPage: 1,//默认第几页
            count:0,//总条数
            limit:10,//每页条数
            form1:{
                id:this.lookLetterData.id,
                date:'',
            },
            form:{
                signup_name:"",//学员姓名
                signup_coopcompany: '',//机构名称
                signup_paytime:'',//缴费日期
                signup_idcard:'',//身份证号
                signup_tel:'',//联系方式
                signup_class:'',//工种
                signup_openclasstype:'',//报名形式 0仅报名 1仅网课 2报名+网课
                signup_classtype:'',//考试形式(传值给后端)
                // signup_classtypearr:[],//考试形式（前端渲染）
                signup_issuer:"",//颁证单位
                areaId:[],//报考地区['1','2']
                signup_level:'',//级别
                signup_price:'',//价格
                signup_openclass:'',//开课情况 0未开课 1已开课
                resp_uid:'',//负责人
                signup_classpwd:'',//密码
                signup_zhkzdate:'',//准考证下发日期
                signup_examtime:"",//考试时间
                signup_score:'',//考试成绩
                signup_achievement:'',//考试结果 0不合格 1合格 
                signup_certdate:'',//下证日期
                signup_expresscode:'',//快递单号 
                signup_expresstime:'',//邮寄时间
                remark:'',
            },
            rules:{
                signup_name:[{required:true,message: '请输入姓名', trigger: 'blur'}],
                signup_coopcompany:[{required:true,message: '请输入机构名称', trigger: 'blur'}],
                signup_paytime:[{required:true,message: '请选择缴费日期', trigger: 'blur'}],
                signup_idcard:[
                {required:true,message: '请选输入身份证号', trigger: 'blur'},
                {pattern: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请输入正确的身份证号', trigger: 'blur' }],
                signup_tel:[
                {required:true,message: '请输入手机号', trigger: 'blur'},
                { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }],
                signup_class:[{required:true,message: '请输入报考工种', trigger: 'blur'}],
                signup_openclasstype:[{required:true,message: '请输入报名形式', trigger: 'blur'}],
                // signup_classtypearr:[{required:true,message: '请选择考试形式', trigger: 'blur'}],
                signup_issuer:[{required:true,message: '请输入颁证单位', trigger: 'blur'}],
                // areaId:[{required:true,message: '请选择报考地区', trigger: 'blur'}],
                // signup_level:[{required:true,message: '请输入报考级别', trigger: 'blur'}],
                signup_price:[{required:true,message: '请输入报名价格', trigger: 'blur'},
                {pattern: /^(\d+|(\d+)?\.\d{1})$/, message: '请输入正确的价格', trigger: 'blur' }],
                signup_score:[{pattern: /^(\d+|(\d+)?\.\d{1})$/, message: '请输入正确的考试成绩', trigger: 'blur' }]
            },
            worktypeList:[],//工种列表
            jigouList:[],//机构列表
            issuerList:[],//颁证单位列表
            directorList:[],//负责人列表
            openclassList:[
                {id:0,value:"未开课"},
                {id:1,value:"已开课"}
            ],//开课情况列表
            openclasstypeList:[
                {id:0,value:"仅报名"},
                {id:1,value:"仅网课"},
                {id:2,value:"报名+网课"}
            ],//报名形式列表
            areaList:this.region,//地区列表
            examresultList:[
                {id:0,value:'不合格'},
                {id:1,value:'合格'}
            ],//考试结果列表
            examList:[
                {
                    id:"0",
                    value:"线上考试"
                },
                {
                    id:"1",
                    value:"线下考试"
                },
                {
                    id:"2",
                    value:"线上考试+线下考试"
                },
            ],//考试形式列表
            classStatusFlag:false,
            letterData:[
                // {
                //     mail_id:1,
                //     content:"XXX学员信息身份信息不正确",
                //     status:'1',
                // },
                // {
                //     mail_id:2,
                //     content:"XXX学员信息身份信息不正确",
                //     status:'1',
                // },
                // {
                //     mail_id:3,
                //     content:"XXX学员信息身份信息不正确",
                //     status:'1',
                // },
                // {
                //     mail_id:4,
                //     content:"XXX学员信息身份信息不正确",
                //     status:'2',
                // }
            ],
            disabled:false,
            disabled2:false,
            fuzerenflag:false,//负责人是都可以选择
            
        }
    },
    created(){
        this.getLetterData();//获取站内信列表
        if(this.roleType == 1){
            this.fuzerenflag = true
            this.form.resp_uid = localStorage.getItem('user_id')
        }else{
            this.fuzerenflag = false
            this.getDirectorList();//获取负责人列表
        }
    },
    methods:{
        handleCheckedChange(val){
            // 点击后参数已经发生变化，重新渲染
            this.$forceUpdate();
        },
        //刷新布局内容
        refreshLayoutContent() {
            //$emit事件名
            EventBus.$emit('refreshLayout');
        },
        //工种列表回调函数
        querySearch(queryString, cb) {
            var worktypeList = this.worktypeList;
            var results = queryString ? worktypeList.filter(this.createFilter(queryString)) : worktypeList;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        //机构列表回调函数
        queryjigouSearch(queryString, cb) {
            var jigouList = this.jigouList;
            var results = queryString ? jigouList.filter(this.createjigouFilter(queryString)) : jigouList;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        //颁证单位列表回调函数
        queryissuerSearch(queryString, cb) {
            var issuerList = this.issuerList;
            var results = queryString ? issuerList.filter(this.createissuerFilter(queryString)) : issuerList;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (worktypeList) => {
                if(worktypeList){
                    return (worktypeList.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                }else{
                    return
                }
            };
        },
        createjigouFilter(queryString) {
            return (jigouList) => {
                if(jigouList){
                    return (jigouList.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                }else{
                    return
                }
            };
        },
        createissuerFilter(queryString) {
            return (issuerList) => {
                if(issuerList){
                    return (issuerList.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                }else{
                    return
                }
            };
        },
        handleSelect(item) {
            // console.log(item);
        },
        changeSignUp(){
            if(this.form.signup_openclasstype == 0){
                this.form.signup_openclass = '';
                this.classStatusFlag = true;
            }else{
                this.classStatusFlag = false;
            }
        },
        //返回到站内信列表
        back(){
            this.flag = !this.flag
        },
        //每页条数
        handleSizeChange(val) {
            this.limit = val;
            this.getLetterData()
        },
        //当前页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getLetterData()
        },
        handleClose(){
            this.lookLetterData.dialogFlag = false;
        },
        //获取站内信列表
        getLetterData(){
            var param = {}
            if(this.lookLetterData.type == 1){
                param = {
                    AdminToken:this.AdminToken,
                    page:this.currentPage,
                    limit:this.limit,
                    signup_id:this.lookLetterData.id
                }
            }else{
                param = {
                    AdminToken:this.AdminToken,
                    page:this.currentPage,
                    limit:this.limit,
                }
            }
            this.$http({
                url:this.authorityId8,
                params:param,
                headers:{
                    AdminToken:this.AdminToken
                }
            }).then(response=>{
                let res = response.data;
                if(res.code == 200){
                    this.letterData = res.data.list
                    for(let i=0;i<res.data.list.length;i++){
                        if(res.data.list[i].status == 1){
                            this.allids.push(res.data.list[i].mail_id)
                        }
                    }
                    this.count = res.data.count
                }else{
                    this.$message.error(res.msg);
                }
            }).catch(res=>{
                this.$message.error(res);
            })
        },
        //标为已读
        changeReadStatus(isall,id,signup_id){
            let that = this
            let arrId = []
            if(isall == 0){
                let index = this.allids.indexOf(id)
                if(index > -1){
                    this.allids = this.allids.splice(index,1)
                }
                if(id){
                    arrId = [id] 
                    
                }else{
                    arrId = this.allids
                }
                console.log(this.allids,"allids",arrId,"arrId")
            }
            let param = {
                AdminToken:this.AdminToken,
                is_all:isall,//0否 1是 是否全部
                mail_ids:arrId,//站内信Id
            }
            this.$post(this.authorityId11,param,this).then((res)=>{
            if(res.code == 200){
                this.getLetterData();//获取站内信列表
                this.refreshLayoutContent()
                that.$emit("refresh")
            }else{
              this.$message.error(res.msg);
            }
          }).catch(res=>{
            this.$message.error(res.message);
          })
        },
        //查看此条站内信的报名信息
        lookEnterInfo(signup_id){
            this.getByIdList(signup_id);//根据signup_id找到对应的提单信息
            this.flag = !this.flag;
        },
        //根据用户ID获取信息
        getByIdList(id){
            this.$http({
                url:this.authorityId4,
                params:{
                    AdminToken:this.AdminToken,
                    signup_id:id,
                },
                headers:{
                    AdminToken:this.AdminToken
                }
            }).then(response=>{
                let res = response.data;
                if(res.code == 200){
                    this.form = res.data
                    if(res.data.state == 1 || res.data.state == 2 || res.data.state == 4){
                        this.disabled = false
                    }else{
                        this.disabled = true
                    }
                    if(res.data.state > 4){
                        this.disabled2 = true
                    }else{
                        this.disabled2 = false
                    }
                    // this.form.signup_classtypearr = this.form.signup_classtype.split(',')
                    this.form.areaId = []
                    if(res.data.signup_province != ''){
                        this.form.areaId.push(res.data.signup_province)
                        if(res.data.signup_city != ''){
                            this.form.areaId.push(res.data.signup_city)
                            if(res.data.signup_area != ''){
                                this.form.areaId.push(res.data.signup_area)
                            }
                        }
                    }else if(res.data.signup_city != ''){
                        this.form.areaId.push(res.data.signup_city)
                        if(res.data.signup_area != ''){
                            this.form.areaId.push(res.data.signup_area)
                        }
                    }else if(res.data.signup_area != ''){
                        this.form.areaId.push(res.data.signup_area)
                    }
                }else{
                    this.$message.error(res.msg);
                }
            }).catch(res=>{
                this.$message.error(res.message);
            })
        },
        //添加
        onSubmit(){
            this.$refs.form.validate((valid)=>{
                if(valid){
                    this.form.AdminToken = this.AdminToken;
                    // this.form.signup_classtype = this.form.signup_classtypearr.join(",");
                    // delete this.form['signup_classtypearr']
                    // console.log(this.form)
                    if(this.form.areaId.length == 1){
                        this.form.signup_province = this.form.areaId[0]
                        this.form.signup_city = ''
                        this.form.signup_area = ''
                    }else if(this.form.areaId.length == 2){
                        this.form.signup_province = this.form.areaId[0]
                        this.form.signup_city = this.form.areaId[1]
                        this.form.signup_area = ''
                    }else if(this.form.areaId.length == 3){
                        this.form.signup_province = this.form.areaId[0]
                        this.form.signup_city = this.form.areaId[1]
                        this.form.signup_area = this.form.areaId[2]
                    }
                    this.form.signup_paytime = this.common.cstToYmdHms(this.form.signup_paytime);//缴费日期时间格式转换
                    this.form.signup_examtime = this.common.cstToYmdHms(this.form.signup_examtime);//考试日期时间格式转换
                    this.form.signup_zhkzdate = this.common.cstToYmdHms(this.form.signup_zhkzdate);//下发准考证日期时间转换
                    this.form.signup_certdate = this.common.cstToYmdHms(this.form.signup_certdate);//下证日期时间转换
                    this.form.signup_expresstime = this.form.signup_expresstime?this.common.cstToYmdHms(this.form.signup_expresstime):'';//邮寄日期时间转换
                    let url = this.authorityId7;
                    //调接口 
                    this.$post(url,this.form,this).then((res)=>{
                        if(res.code == 200){
                            this.$message({
                                message: '添加成功',
                                type: 'success'
                            });
                        }else{
                            this.$message.error(res.msg);
                        }
                    }).catch(res=>{
                        
                    })
                }else{
                    return false
                }
            })
        },
        //获取负责人列表
        getDirectorList(){
        this.$http({
            url:this.authorityId25,
            params:{
                AdminToken:this.AdminToken,
                page:0,
                limit:0,
                role_type:1,//0运营 1销售
            },
            headers:{
                AdminToken:this.AdminToken
            }
        }).then(response=>{
            let res = response.data;
            if(res.code == 200){
                this.directorList = res.data.list
            }else{
                this.$message.error(res.msg);
            }
        }).catch(res=>{
            this.$message.error(res.message);
        })
        },
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep .el-input{
    width:234px
    }
    ::v-deep .el-textarea__inner{
    width:234px;
    }
    .letter-box{
        border: 1px solid #cacaca;
        margin-bottom: 5px;
        padding: 5px 10px;
        border-radius: 4px;
    }
    .weidu-letter-box{
        border: 1px solid #cacaca;
        margin-bottom: 5px;
        padding: 5px 10px;
        border-radius: 4px;
        background-color:rgb(255,230,230);
    }
</style>