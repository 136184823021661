let myurl = location.href;
var url = null;
if (myurl.indexOf('bjqiyang') > -1) {
  url = 'https://signup.bjqiyang.com';
} else if (myurl.indexOf('dev') > -1) {
  url = 'https://signup.bjqiyang.com'
} else if (myurl.indexOf('localhost:') > -1) {
    url='http://192.168.1.39:83'
}else if(myurl.indexOf('192.168.1.139:')>-1){
    url='http://192.168.1.39:83'
}

export default {
  url,
}