<template>
  <div class="layout">
    <el-container>
        <el-header>
            <!-- logo -->
            <div class="logo">
                <img src="@/assets/images/logo.png" alt="" style="width:200px">
            </div>
            <!-- 导航菜单 -->
            <el-menu :router="true" :default-active="$route.path"  class="el-menu-demo" mode="horizontal" @select="handleSelect">
                <el-menu-item  v-for="(item,index) in route" :index="item.path" v-show="!item.meta.hidden" :key="index"><span>{{ item.meta.title }}</span></el-menu-item>
            </el-menu>
            <!-- 下拉操作 -->
            <el-dropdown class="user">
                <span><img src="@/assets/images/user.svg" alt="" width="30" style="margin-left: 30px;"></span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item><span @click="exit()" style="display:inline-block;width:100%;height:100%;z-index:2060">退出</span></el-dropdown-item>
                  <el-dropdown-item><span @click="editPassword()" style="display:inline-block;width:100%;height:100%;z-index:2060">修改密码</span></el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <!-- 站内信消息提示 -->
            <!-- <el-badge :value="notReadNum" class="news"> -->
                <div style="display: inline-block;position:relative;cursor:pointer;" class="news">
                    <img src="@/assets/images/news.svg" alt="" @click="lookLetter()">
                    <!-- width:15px;height:15px; -->
                    <span v-if="notReadNum>0" style="background:#f56c6c;color:#fff;border-radius:10px;position:absolute;top:-3px;right:-5px;padding:1px 5px;text-align:center;font-size:12px;line-height:15px;">{{ notReadNum }}</span>
                </div>
            <!-- </el-badge> -->
        </el-header>
        <el-main>
            <router-view></router-view>
        </el-main>
    </el-container>
    <div>
        <editPassword
            v-if="editPasswordData.dialogFlag"
            :editPasswordData="editPasswordData"
            @Handle="editPasswordHandle"
        />  
        <lookLetter
            v-if="lookLetterData.dialogFlag"
            :lookLetterData="lookLetterData"
            @Handle="lookLetterHandle"
        /> 
    </div>
  </div>
</template>

<script>
import editPassword from '@/components/index/editPassword.vue'
import lookLetter from '@/components/registrationManagement/lookLetter.vue'
import { postAjax } from '../../utils/https'
import { EventBus } from '@/event-bus.js';
export default {
    // name: 'LayoutComponent',
    components:{ editPassword,lookLetter },
    data(){
        return{
            authorityId2:this.urlApi.API.authorityId2,//退出登录
            authorityId12:this.urlApi.API.authorityId12,//站内信未读数量
            activeIndex: '1',
            route:this.$router.options.routes,//路由
            notReadNum:'',//站内信未读数量
            editPasswordData:{
                dialogFlag:false
            },
            lookLetterData:{
                dialogFlag:false,
                all:1
            },
            AdminToken:localStorage.getItem('token')
        }
    },
    created(){
        //$on 事件名 事件回调函数
        EventBus.$on('refreshLayout', this.getLetterNotReadNum);
    },
    mounted(){
        this.getLetterNotReadNum()
    },
    methods:{
        handleSelect(key, keyPath) {
            // console.log(key, keyPath);
        },
        // refreshLayout(){
        //     this.getLetterNotReadNum()
        // },
        //获取站内信未读数量
        getLetterNotReadNum(){
            this.$post(this.authorityId12,{AdminToken:this.AdminToken},this).then((res)=>{
            if(res.code == 200){
                this.notReadNum = res.data.count
            }else{
              this.$message.error(res.msg);
            }
          }).catch(res=>{
            this.$message.error(res.msg);
          })
        },
        //退出
        exit(){
            this.$post(this.authorityId2,{AdminToken:this.AdminToken},this).then((res)=>{
                if(res.code == 200){
                    localStorage.clear();
                    this.$router.push({path:'/login'})
                }else{
                    this.$message.error(res.msg);
                }
            }).catch(res=>{
                
            })
        },
        //修改密码
        editPassword(){
            this.editPasswordData.dialogFlag = true
        },
        //关闭修改密码弹框
        editPasswordHandle(){
            this.editPasswordData.dialogFlag = false
        },
        //查看站内信弹窗
        lookLetter(){
            this.lookLetterData.dialogFlag = true;
            this.lookLetterData.type = 2;//查看当前用户所有站内信
            // this.lookLetterData.id = row.id;
        },
        //关闭查看站内信弹窗
        lookLetterHandle(){
            this.lookLetterData.dialogFlag = false;
        },
    },
    beforeDestroy() {
        EventBus.$off('refreshContent', this.refreshContent);
    }
}
</script>

<style lang="scss" scoped>
    .layout{
        .el-container{
            height: 100vh;
            width: 100vw;
            background: #EFF8FF;
        }
        .el-menu{
            background: rgba(0,0,0,0);
        }
        .el-menu.el-menu--horizontal{
            border:none;
        }
        .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
        .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
            background-color: rgba(0,0,0,0);
        }
        .el-menu--horizontal > .el-menu-item{
            height: 30px;
            line-height: 30px;
        }
        .logo,.el-menu-demo{
            float: left;
        }
        .news,.user{
            float: right;
        }
        .el-header{
            padding: 20px;
        } 
        .el-menu-demo{
            margin-left: 50px;
        }
        .el-menu-item{
            padding: 0px;
            margin-right: 30px;
        }
    }
</style>